<template>
  <Header/>
  <router-view/>
</template>
<script>
import Header from '@/components/Header'
export default {
  components:{
    Header
  },
}
</script>
<style>
body{
  margin: 0;
  padding: 0;
}
html{
  font-size:2vw;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 0;
  padding: 0;
}
a{
  text-decoration:none;
}

</style>
