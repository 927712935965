<template>
    <header class="nav">
    <ul class="leftNavUl">
      <li> <a href="#">Services</a></li>
      <li><a href="#">About</a></li>
    </ul>
    <div class="homeLogo">
      <img src="@/assets/images/harveys2.png" alt="logo">
    </div>
    <ul class="rightNavUl">
      <li><a href="#">Links</a></li>
      <li><a href="#">Contact</a></li>
    </ul>
  </header>
</template>

<script>
export default {

}
</script>

<style scoped>
.nav {
  background: lightgrey;
  height: 150px;
  display: flex;
  align-items:center;
}

.nav li{
  text-decoration: none;
  display: inline;
  margin: 0 15px;
  padding:0;

}
.nav li a{

  color: rgb(45, 171, 194);
  font-weight:bold;
  font-size:1.0em;
  font-family:Impact;
}
.nav img{
  /* height: 150px; */
  width: 100%;
  max-width:450px;
}
.leftNavUl{
  flex-grow: 5;
  display: flex;
  justify-content:flex-end;
}
.rightNavUl{
  flex-grow: 5;
  display: flex;
  justify-content:flex-start;
}
.homeLogo{
  flex-grow:1;
}
</style>